.infoTitle {
  font-size: var(--font-size-large);
  font-weight: 600;
  color: var(--blue-dark);
}

.infoImageWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.infoImage {
  max-width: 95%;
}

.infoDescription {
  margin-top: 8px;

  text-align: center;
  color: var(--blue-dark);
}

.infoDescription .bold {
  font-weight: 600;
}

.infoDescription .pink {
  color: var(--pink);
}
