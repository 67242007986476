.windowBackground {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  inset: 0;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 10;
}

.window {
  position: fixed;

  border-radius: 8px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  overflow: hidden;
}

.closeButton {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
