.inputContainer {
  position: relative;
}

.input {
  background-color: #f6f6f6;

  border: 1px solid #e8e8e8;
  border-radius: 8px;

  padding: 0 16px;

  width: 100%;
  height: 50px;

  font-family: var(--font-sub);
  color: var(--black);
}

.input.error {
  border: 1px solid var(--red);
}

.input.accepted {
  border: 1px solid var(--green);
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #bdbdbd;
}

.showPasswordButton {
  position: absolute;
  right: 16px;
  bottom: calc(50% - 12px);

  margin: 0;

  font-weight: 500;
  color: var(--blue-secondary);

  cursor: pointer;
}
