.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoImage {
  width: 100%;
}

.logoTitle {
  margin-top: 8px;

  font-size: 1rem;
  color: var(--black);
}

.logoSecondTitle {
  font-size: 1rem;
  color: var(--black-secondary);
}
