@font-face {
  font-family: "NIXGONM-Vb";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/NIXGONM-Vb.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* fonts */
  --font-main: "NIXGONM-Vb", sans-serif;
  --font-sub: "Poppins", sans-serif;

  /* colours */
  --black: #000000;
  --black-secondary: #3a3934;

  --white: #ffffff;

  --blue-primary: #7680e2;
  --blue-secondary: #463bd5;

  --red: #ff0000;
  --yellow: #f7c977;
  --green: #99bf40;
  --pink: #f25c9d;
  --orange: #f0a370;
  --sky-blue: #8acdf4;
  --blue-dark: #222888;
  --night-blue: #4753A7;
  --tangerine: #eb7452;

  --gray: #d9d9d9;
  --dark-gray: #A5A5A5;

  /* sizes */
  --font-size-x-large: 32px;
  --font-size-large: 24px;
  --font-size-middle: 16px;
  --font-size-small: 12px;
  --font-size-x-small: 8px;
}

* {
  box-sizing: border-box;
}

html,
body {
  background-color: #f5f5f5;

  width: 100dvw;
  height: 100dvh;

  margin: 0;

  overflow: hidden;

  font-family: var(--font-main);
  font-size: var(--font-size-middle);
}

button {
  font-family: var(--font-main);
}

#root {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;

  line-height: 1.5em;
}
