@font-face {
  font-family: 'NIXGONM-Vb';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/NIXGONM-Vb.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.App {
  text-align: center;
  width: 100%;
  height: 100%;
}
.App *{
  box-sizing: border-box;
  
  font-family: 'NIXGONM-Vb', 'Poppins';
}
.App input[type="password"]{
  font-family: Arial, sans-serif;
}
.App input[type="password"]::placeholder{
  font-family: 'NIXGONM-Vb', 'Poppins';
}
