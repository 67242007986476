.confirmModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  position: relative;

  padding: 48px;

  background-color: var(--blue-primary);
}

.confirmModalHeader {
  font-size: var(--font-size-large);
  color: var(--white);
}

.classList {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  list-style: none;
  color: var(--white);
}

.submitButton {
  position: relative;
  left: 40px;

  margin-top: 24px;

  width: 60%;

  font-weight: 600;
  color: var(--blue-primary);
}

.image {
  position: absolute;
  left: 10px;
  bottom: 0;
}

.error {
  position: absolute;
  bottom: 0;

  font-size: var(--font-size-small);
  color: var(--red);
}
