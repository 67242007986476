.infoQuizPage {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  left: 0;

  width: 40%;
}

.infoQuizContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;

  padding-right: 24px;

  text-align: right;
}

.infoQuizHeader {
  margin-bottom: 8px;

  font-size: var(--font-size-x-large);
  font-weight: 600;
  color: var(--blue-dark);
}

.infoQuizHeader .yellow {
  color: var(--yellow);
}

.infoQuizSecondHeader {
  font-size: var(--font-size-middle);
  font-weight: 600;
  color: var(--blue-secondary);
}

.infoQuizConditionContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 500;
  color: var(--blue-dark);
}

.infoQuizCaution {
  margin-top: 4px;

  font-weight: 500;
  font-size: var(--font-size-small);
  color: var(--tangerine);
}

.infoQuizCaution .bold {
  font-weight: 600;
}

.button {
  background-color: var(--blue-primary);

  margin-top: 28px;

  font-weight: 600;
  color: white;
}
