.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  left: 0;
  bottom: 5%;

  padding: 0 10%;

  width: 100%;
  height: 30px;
}

.button {
  flex-basis: 30px;

  cursor: pointer;
}

.indexPointerContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.indexPointer {
  border: 4px solid;
  border-radius: 8px;
}
