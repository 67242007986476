.DashBoardPage {
  width: 100%;
  height: 100%;

  margin-top: 32px;
}

.scrollWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: calc(100% - 100px);

  overflow: auto;
}

.dashBoardHeaderContainer {
  width: 100%;
  height: 52px;

  padding: 0 16px;
}

.dashBoardHeader {
  font-weight: 400;
  font-size: var(--font-size-middle);
}

.dashBoardHeader .bold {
  font-weight: 600;
}

.dashBoardEngHeader {
  font-weight: 400;
  font-size: var(--font-size-small);
  color: #a5a5a5;
}

.infoButtonContainer {
  display: flex;
  gap: 8px;
}

.infoButton {
  background-color: var(--green);

  border: none;
  border-radius: 8px;

  padding: 12px;

  box-shadow: 0px 4px 4px 0px #00000040;

  color: var(--white);
}

.infoButton:disabled {
  background-color: #a5a5a5;

  box-shadow: none;
}

.dividor {
  margin: 8px 0;

  border-top: 1px solid #a5a5a5;

  width: 90%;
}
