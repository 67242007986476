.statusContainer {
  margin-top: 32px;

  width: 90%;
}

.statusHeaderWrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.statusHeader {
  padding: 4px 8px;
}

.statusImageWrapper {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  margin-right: 16px;
}

.statusBox {
  width: 100%;


  border-radius: 8px;

  display: flex;
}

.statusContentWrapper {
  width: 100%;
  
  padding: 32px 32px 16px;
}
.statusContentWrapperShrink {
  width: 55%;
  min-width: 190px;

  padding: 8px;
}
.statusBackgroundImageWrapper{
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.statusBackgroundImageWrapper>img{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.statusWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: 16px;
  
}

.statusItemWrapper {
  flex-basis: 33%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.shieldWrapper {
  display: flex;
  align-items: center;

  margin-bottom: 8px;

  font-size: var(--font-size-small);
  color: var(--blue-secondary);
  letter-spacing: -0.1em;
}

.shieldWrapper > p {
  position: relative;
  top: 1px;

  line-height: 1em;
}

.statusValue {
  font-weight: 600;
  font-size: var(--font-size-large);
  color: var(--black);
}

.statusLabel {
  font-size: var(--font-size-small);
}

.horizontalLine {
  border-top: 1px solid var(--white);
}

.statusText {
  margin-top: 16px;

  font-size: var(--font-size-small);
  text-align: center;

  color: var(--black);
}
