.quizResultModal {
  display: flex;
  align-items: center;

  background-color: var(--blue-primary);

  padding: 48px 16px;
}

.quizResultModalImageContainer {
  position: absolute;
  left: 0;
  bottom: 0;

  width: 140px;

  text-align: center;
}

.quizResultModalImage {
  vertical-align: bottom;
}

.quizResultModalTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: 100px;
}

.quizResultModalHeader {
  font-size: var(--font-size-large);
  color: var(--white);
}

.quizResultModalDescription {
  font-size: var(--font-size-small);
  color: var(--white);
}

.button {
  position: relative;
  left: 10px;

  margin-top: 40px;

  font-weight: 600;
  color: var(--blue-primary);
}
